/** @jsx jsx */
import { Box, Button, Flex, jsx } from 'theme-ui'
import Text from '~/components/IntlComponents/Text'

import { useStaticQuery, graphql } from 'gatsby'

import { Fragment } from 'react'
import Modal from 'react-responsive-modal'
import Spinner from '~/components/Generic/Spinner'

const CancelPopUp = ({
  close,
  event,
  pause,
  popUp,
  apiError,
  isFetching,
  renewalDate,
  type,
  slug,
  state,
}) => {
  const allData = useStaticQuery(graphql`
    {
      allContentfulNotificationBar {
        nodes {
          slug
          notification {
            name
            slug
            description {
              childrenMarkdownRemark {
                html
              }
            }
            id
          }
        }
      }
    }
  `)

  const data = allData.allContentfulNotificationBar.nodes.find(
    d => d.slug === slug,
  )

  const closeIcon = (
    <svg fill="#FF5A47" width="28" height="28" viewBox="0 0 36 36">
      <path d="M28.5 9.62L26.38 7.5 18 15.88 9.62 7.5 7.5 9.62 15.88 18 7.5 26.38l2.12 2.12L18 20.12l8.38 8.38 2.12-2.12L20.12 18z"></path>
    </svg>
  )

  return (
    <Modal
      closeIcon={closeIcon}
      aria-labelledby="cancel-modal"
      aria-describedby="confirmation modal for cancelling subscriptions"
      classNames={{
        overlay: 'customOverlay',
        modal: 'customModal',
      }}
      open={popUp}
      onClose={close}
      center
    >
      <Box sx={{ padding: '30px', textAlign: 'center' }}>
        <Box
          sx={{
            color: '#FF5A47',
            h3: {
              fontFamily: 'heading',
              textTransform: 'uppercase',
              fontSize: ['28px', '40px'],
            },
            pb: '60px',
          }}
        >
          <Text
            sx={{ p: { display: 'inline' } }}
            dangerouslySetInnerHTML={{
              __html:
                data?.notification?.description?.childrenMarkdownRemark?.[0]
                  ?.html,
            }}
          />
          {type === 'skip' && renewalDate}
        </Box>

        {apiError && (
          <Text
            variant="formError"
            sx={{
              color: '#FF5947',
              backgroundColor: 'errorLight',
              px: '16px',
              py: '8px',
            }}
          >
            {apiError}
          </Text>
        )}
        <Flex
          sx={{
            width: '100%',
            justifyContent: 'center',
            gap: '10px',
            flexDirection: ['column', 'row'],
            alignItems: 'center',
          }}
        >
          {isFetching ? (
            <Spinner />
          ) : (
            <Fragment>
              <Button variant="invert" sx={{ color: 'white' }} onClick={close}>
                Keep Subscription
              </Button>
              {type === 'cancel' && state !== 'paused' && (
                <Button
                  onClick={pause}
                  sx={{
                    background: '#45EEFF',
                  }}
                >
                  Pause Instead
                </Button>
              )}
              <Button
                onClick={event}
                sx={{
                  border: '2px solid #FF5947',
                  color: '#FF5947',
                  background: 'transparent',
                  textTransform: 'capitalize',
                  ':hover': {
                    background: '#FF5947',
                    color: 'white',
                  },
                }}
              >
                {type}
              </Button>
            </Fragment>
          )}
        </Flex>
      </Box>
    </Modal>
  )
}

export default CancelPopUp
