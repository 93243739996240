/** @jsx jsx */
import { Button, Input, Flex, Grid, jsx } from 'theme-ui'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import {
  useSubscription,
  useTranslate,
} from '@chordcommerce/gatsby-theme-autonomy'
import Spinner from '~/components/Generic/Spinner'
import Text from '~/components/IntlComponents/Text'

const inputStyle = {
  gridColumn: '2 / -1',
  border: '1px solid',
  borderColor: 'secondary',
  color: 'secondary',
  borderRadius: '8px',
  background: 'white',
  '&:focus': { color: 'secondary', borderColor: 'secondary' },
}

const flexStyle = {
  display: ['flex', 'grid'],
  gridTemplateColumns: '1fr 1fr 138px',
  flexDirection: ['column', 'row'],
  justifyContent: 'space-between',
  mb: '21px',
  alignItems: ['left', 'center'],
}

const textStyle = {
  textAlign: 'left',
  marginRight: '3rem',
}

// TODO: Address validation
const SubscriptionDetailsAddressForm = ({
  addressType,
  subscription,
  close,
}) => {
  const translate = useTranslate()

  const { updateSubscription } = useSubscription({
    subscriptionId: subscription.id,
  })

  const address = subscription[addressType]

  const { register, handleSubmit } = useForm({
    defaultValues: {
      name: address.name,
      address1: address.address1,
      address2: address.address2,
      city: address.city,
      state_name: address.state.abbr,
      country_iso: address.country.iso,
      zipcode: address.zipcode,
    },
  })

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const onSubmit = async data => {
    setLoading(true)
    setError(null)

    try {
      const paramsPrefix = `${addressType}Attributes`
      await updateSubscription({
        [paramsPrefix]: data,
      })
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
      close()
    }
  }

  return (
    <Flex
      as="form"
      onSubmit={handleSubmit(onSubmit)}
      sx={{ flexDirection: 'column' }}
    >
      <Flex sx={flexStyle}>
        <Text sx={textStyle}>{translate('address.name')}</Text>
        <Input sx={inputStyle} {...register('name', { maxLength: 80 })} />
      </Flex>

      <Flex sx={flexStyle}>
        <Text sx={textStyle}>{translate('address.address1')}</Text>
        <Input
          sx={inputStyle}
          {...register('address1', { required: true, maxLength: 80 })}
        />
      </Flex>

      <Flex sx={flexStyle}>
        <Text sx={textStyle}>{translate('address.address2')}</Text>
        <Input sx={inputStyle} {...register('address2')} />
      </Flex>

      <Flex sx={flexStyle}>
        <Text sx={textStyle}>{translate('address.city')}</Text>
        <Input
          sx={inputStyle}
          {...register('city', { required: true, maxLength: 80 })}
        />
      </Flex>

      <Flex sx={flexStyle}>
        <Text sx={textStyle}>{translate('address.state')}</Text>
        <Input
          sx={inputStyle}
          {...register('state_name', { required: true, maxLength: 80 })}
        />
      </Flex>

      <Flex sx={flexStyle}>
        <Text sx={textStyle}>{translate('address.country')}</Text>
        <Input
          sx={inputStyle}
          {...register('country_iso', {
            required: true,
            maxLength: 80,
            hidden: true,
          })}
        />
      </Flex>

      <Flex sx={flexStyle}>
        <Text sx={textStyle}>{translate('address.zip')}</Text>
        <Input
          sx={inputStyle}
          {...register('zipcode', { required: true, maxLength: 80 })}
        />
      </Flex>

      {error && <Text variant="formError">{error.message}</Text>}
      <Grid
        sx={{
          gridTemplateColumns: '1fr 1fr 138px',
          display: ['block', 'grid'],
        }}
      >
        <Flex
          sx={{
            mb: '40px',
            justifyContent: ['space-between', 'flex-start'],
            gridColumn: '2/-1',
          }}
        >
          <Button
            type="submit"
            variant="buttons.subscription"
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {loading && (
              <Spinner data-testid="spinner" size="15" color="inherit" />
            )}
            {!loading && 'Update'}
          </Button>
          <Button variant="link" onClick={close}>
            {translate('address.cancel')}
          </Button>
        </Flex>
      </Grid>
    </Flex>
  )
}

export default SubscriptionDetailsAddressForm
