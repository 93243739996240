/** @jsx jsx */
import { useTranslate } from '@chordcommerce/gatsby-theme-autonomy'
import React, { Fragment } from 'react'
import { Grid, Heading, Text, jsx } from 'theme-ui'
import TableElement from './TableElement'
import OrderDetailsLineItems from './LineItems'
import ShippingAddress from './ShippingAddress'
import Spinner from '~/components/Generic/Spinner'
import useOrder from '~/hooks/utils/use-order'
import { formatMoney } from '~/utils/format'
import { localeFromPathname } from '~/utils/intl/localePrefix'

const OrderDetails = ({ orderNumber }) => {
  const { order, error, isLoading } = useOrder(orderNumber)
  const translate = useTranslate()
  if (error) {
    return (
      <Grid sx={{ placeItems: 'center', minHeight: '10rem' }}>
        <Heading>{translate('error.api.default')}</Heading>
      </Grid>
    )
  }

  if (isLoading || !order) {
    return (
      <Grid sx={{ placeItems: 'center' }}>
        <Spinner />
      </Grid>
    )
  }
  const { code: locale } = localeFromPathname()
  const {
    lineItems,
    currency,
    adjustmentTotal,
    displayItemTotal,
    displayShipTotal,
    displayTaxTotal,
    displayTotal,
  } = order

  return (
    <Grid
      sx={{
        width: '100%',
        maxWidth: '42rem',
        mt: '8rem',
        mx: 'auto',
        color: 'japaneseLaurel',
        gridGap: '0',
      }}
    >
      <TableElement title={translate('account.orders.date')} separator={true}>
        {orderNumber}
      </TableElement>
      <OrderDetailsLineItems lineItems={lineItems} currency={currency} />

      <TableElement title={translate('account.orders.subtotal')} topPadding>
        {displayItemTotal}
      </TableElement>
      <TableElement title={translate('account.orders.shipping')}>
        {displayShipTotal}
      </TableElement>
      <TableElement title={translate('account.orders.promotions')}>
        {formatMoney(adjustmentTotal, { locale: locale ?? 'en-US', currency })}
      </TableElement>
      <TableElement title={translate('account.orders.taxes')} separator>
        {displayTaxTotal}
      </TableElement>
      <TableElement title={translate('account.orders.total')} topPadding>
        {displayTotal}
      </TableElement>
      <ShippingAddress shipAddress={order?.shipAddress} />
      <TableElement
        title={translate('account.orders.shipping_method')}
        forwardSx={{ flexDirection: ['column', 'row'] }}
      >
        {order &&
          order?.shipments?.map(shipment => (
            <Text key={shipment.id}>
              {shipment?.selectedShippingRate?.name}
            </Text>
          ))}
      </TableElement>

      <TableElement
        title={translate('account.orders.payment_method')}
        forwardSx={{ flexDirection: ['column', 'row'] }}
      >
        {order?.creditCards &&
          order?.creditCards?.map(card => (
            <Fragment key={card?.id}>
              <Text sx={{ textTransform: 'capitalize' }}>{card?.ccType} </Text>
              <Text>
                ({Array.from({ length: 12 }, () => '*')}
                {card?.lastDigits})
              </Text>
            </Fragment>
          ))}
      </TableElement>
    </Grid>
  )
}

export default OrderDetails
