/** @jsx jsx */
import { useTranslate } from '@chordcommerce/gatsby-theme-autonomy'
import React from 'react'
import { Flex, Grid, Text, jsx } from 'theme-ui'

const ShippingAddress = ({ shipAddress }) => {
  const translate = useTranslate()
  return (
    <Flex
      sx={{
        flexDirection: ['column', 'row'],
        justifyContent: 'space-between',
        mt: '3.5rem',
        mb: '2.5rem',
        fontSize: '1.25rem',
        lineHeight: 1.6,
      }}
    >
      <Text>{translate('account.orders.shipping_address')}</Text>
      <Grid sx={{ textAlign: [null, 'right'], gridGap: 0 }}>
        <Text sx={{ fontWeight: 'bold' }}>{shipAddress?.name}</Text>
        <Text>{shipAddress?.address1}</Text>
        <Text>{shipAddress?.address2}</Text>
        <Text>
          {shipAddress?.city} {shipAddress?.stateText} {shipAddress?.zipcode}
        </Text>
      </Grid>
    </Flex>
  )
}

export default ShippingAddress
