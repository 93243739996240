/** @jsx jsx */
import { useEffect } from 'react'
import { Box, jsx, useColorMode } from 'theme-ui'

import { navigate } from 'gatsby'
import { useTranslate } from '@chordcommerce/gatsby-theme-autonomy'
import { useLocation } from '@reach/router'
import Heading from '~/components/IntlComponents/Heading'
import AuthLoginForm from '~/components/Auth/LoginForm'
import { toURL } from '~/utils/intl/localePrefix'
const AccountLoginPage = () => {
  const translate = useTranslate()
  const [_, setColorMode] = useColorMode()
  const location = useLocation()
  //run on mount
  useEffect(() => {
    setColorMode('twist-my-parm')
  }, [setColorMode])

  const onLogin = () => {
    navigate(toURL(location && location.pathname, '/account'))
  }

  return (
    <Box
      sx={{
        margin: ['0 auto 1.5rem auto', null, '42px auto 4.5rem auto'],
        maxWidth: '600px',
      }}
    >
      <Box
        sx={{
          margin: ['0 auto 1.5rem auto', null, '42px auto 4.5rem auto'],
          maxWidth: '600px',
        }}
      >
        <AuthLoginForm onSuccess={onLogin} />
      </Box>
    </Box>
  )
}

export default AccountLoginPage
