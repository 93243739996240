/** @jsx jsx */
import { Button, Input, Flex, Grid, jsx } from 'theme-ui'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import {
  useSubscription,
  useTranslate,
} from '@chordcommerce/gatsby-theme-autonomy'
import Text from '~/components/IntlComponents/Text'
import Spinner from '~/components/Generic/Spinner'

const inputStyle = {
  gridColumn: '2 / -1',
  border: '1px solid',
  borderColor: 'secondary',
  color: 'secondary',
  borderRadius: '8px',
  background: 'white',
  '&:focus': { color: 'secondary', borderColor: 'secondary' },
}

const flexStyle = {
  display: ['flex', 'grid'],
  gridTemplateColumns: '1fr 1fr 138px',
  flexDirection: ['column', 'row'],
  justifyContent: 'space-between',
  mb: '21px',
  alignItems: ['left', 'center'],
}

const textStyle = {
  textAlign: 'left',
  marginRight: '3rem',
}

const SubscriptionBillingAddress = ({
  address,
  setAddress,
  loading,
  register,
  error,
  close,
}) => {
  const translate = useTranslate()
  const onChange = e => {
    setAddress({ ...address, [e.target.name]: e.target.value })
  }

  return (
    <Flex sx={{ flexDirection: 'column' }}>
      <Flex sx={flexStyle}>
        <Text sx={textStyle}>{translate('address.name')}</Text>
        <Input
          sx={inputStyle}
          defaultValue={address.name}
          {...register('name', { required: true, maxLength: 80 })}
          onChange={e => onChange(e)}
          name="name"
        />
      </Flex>

      <Flex sx={flexStyle}>
        <Text sx={textStyle}>{translate('address.address1')}</Text>
        <Input
          sx={inputStyle}
          defaultValue={address.address1}
          {...register('address1', { required: true, maxLength: 80 })}
          onChange={e => onChange(e)}
          name="address1"
        />
      </Flex>

      <Flex sx={flexStyle}>
        <Text sx={textStyle}>{translate('address.address2')}</Text>
        <Input
          sx={inputStyle}
          defaultValue={address.address2}
          {...register('address2')}
          onChange={e => onChange(e)}
          name="address2"
        />
      </Flex>

      <Flex sx={flexStyle}>
        <Text sx={textStyle}>{translate('address.city')}</Text>
        <Input
          sx={inputStyle}
          onChange={e => onChange(e)}
          {...register('city', { required: true, maxLength: 80 })}
          defaultValue={address.city}
          name="city"
        />
      </Flex>

      <Flex sx={flexStyle}>
        <Text sx={textStyle}>{translate('address.state')}</Text>
        <Input
          sx={inputStyle}
          onChange={e => onChange(e)}
          defaultValue={address.state.abbr}
          {...register('state_name', { required: true, maxLength: 2 })}
          name="state_name"
        />
      </Flex>

      <Flex sx={flexStyle}>
        <Text sx={textStyle}>{translate('address.country')}</Text>
        <Input
          sx={inputStyle}
          onChange={e => onChange(e)}
          {...register('country_iso', { required: true, maxLength: 3 })}
          name="country_iso"
          defaultValue={address.country.iso}
        />
      </Flex>

      {/* Collect zipcode in CardElement instead  */}
      {/* <Flex sx={flexStyle}>
          <Text sx={textStyle}>{translate('address.zip')}</Text>
          <Input
            sx={inputStyle}
            onChange={e => onChange(e)}
            {...register('zipcode', { required: true, maxLength: 80 })}
            type="zipcode"
            name="zipcode"
            defaultValue={address.zipcode}
          />
        </Flex> */}

      {error && <Text variant="formError">{error.message}</Text>}
      <Grid
        sx={{
          gridTemplateColumns: '1fr 1fr 138px',
          display: ['block', 'grid'],
        }}
      >
        <Flex
          sx={{
            mb: '40px',
            justifyContent: ['space-between', 'flex-start'],
            gridColumn: '2/-1',
          }}
        >
          <Button
            type="submit"
            variant="buttons.subscription"
            disabled={loading}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {loading && (
              <Spinner data-testid="spinner" size="15" color="inherit" />
            )}
            {!loading && 'Update'}
          </Button>
          <Button variant="link" onClick={close}>
            {translate('address.cancel')}
          </Button>
        </Flex>
      </Grid>
    </Flex>
  )
}

export default SubscriptionBillingAddress
