import { useEffect, useState } from 'react'
import useProductBySku from './use-product-by-sku'

const useProductOverHeadImage = ({ sku }) => {
  const [image, setImage] = useState(null)
  const product = useProductBySku(sku)

  useEffect(() => {
    if (!product) return
    const image = product.overheadProductImage
    if (image) setImage(image)
  }, [product, sku])

  return { image }
}

export default useProductOverHeadImage
