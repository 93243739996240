import React from 'react'
import { Container, Flex } from 'theme-ui'
import Spinner from '~/components/Generic/Spinner'

export const LoadingSpinner = () => {
  return (
    <Container>
      <Flex
        sx={{
          flexDirection: 'column',
          marginBottom: ['1.5rem', null, '4.5rem'],
          marginTop: ['0', null, '42px'],
        }}
      >
        <Flex
          sx={{
            justifyContent: 'center',
            width: '100%',
            backgroundColor: 'transparent',
            padding: ['10rem 1.25rem', '12rem 1.25rem'],
            marginRight: [null, '1rem'],
            marginBottom: ['1rem', null],
          }}
        >
          <Spinner size={150} />
        </Flex>
      </Flex>
    </Container>
  )
}
