/** @jsx jsx */
import { useTranslate } from '@chordcommerce/gatsby-theme-autonomy'
import { Fragment } from 'react'
import { Grid, Text, jsx } from 'theme-ui'

const OrderDetailsLineItems = ({ lineItems }) => {
  const translate = useTranslate()
  return (
    <Grid
      sx={t => ({
        gridTemplateColumns: 'repeat(3, 1fr)',
        py: '1.5rem',
        borderBottom: `1px solid ${t.colors.schoolBusYellow}`,
        fontSize: '1.25rem',
      })}
    >
      <Text
        sx={{
          gridColumn: '1/2',
        }}
      >
        {translate('account.orders.items')}
      </Text>
      {lineItems.map(lineItem => {
        return (
          <Fragment key={lineItem.id}>
            <Grid sx={{ gridColumn: ['1/3', '2/3'], gridGap: 0 }}>
              <Text sx={{ fontWeight: 'bold' }}>
                {lineItem.variant.name} x{lineItem.quantity}{' '}
              </Text>
              <Text
                sx={{
                  fontSize: '1rem',
                  color: '#03A009',
                }}
              >
                {/pack/i.test(lineItem.variant.optionsText)
                  ? `${lineItem.variant.optionValues[0].presentation} pack`
                  : null}
              </Text>
            </Grid>
            <Text sx={{ gridColumn: '3/4', justifySelf: 'end' }}>
              {lineItem.displayAmount}
            </Text>
          </Fragment>
        )
      })}
    </Grid>
  )
}

export default OrderDetailsLineItems
