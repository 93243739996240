/** @jsx jsx */
import { jsx, useColorMode } from 'theme-ui'
import { useEffect } from 'react'
import { useTranslate } from '@chordcommerce/gatsby-theme-autonomy'
import OrderDetails from '..'
import ProfileLayout from '~/components/Account/Layout'

const OrderDetailsPage = ({ orderNumber }) => {
  const [_, setColorMode] = useColorMode()
  const translate = useTranslate()
  useEffect(() => {
    setColorMode('cheddy-mac')
  }, [setColorMode])

  return (
    <ProfileLayout
      title={translate('account.orders.details_page_title')}
      link="/account/orders"
      isOrderHistoryPage={true}
    >
      <OrderDetails orderNumber={orderNumber} />
    </ProfileLayout>
  )
}

export default OrderDetailsPage
