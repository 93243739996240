/** @jsx jsx */

import { Flex, Text, jsx } from 'theme-ui'

const MobileTableElement = ({ title, children }) => {
  return (
    <Flex sx={{ flexDirection: 'column' }}>
      <Text sx={{ fontSize: '1rem', fontWeight: 'normal', mb: '0.625rem' }}>
        {title}:
      </Text>
      <Text
        sx={{
          fontWeight: 'bold',
          fontSize: '1.25rem',
        }}
      >
        {children}
      </Text>
    </Flex>
  )
}

export default MobileTableElement
