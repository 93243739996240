/** @jsx jsx */
import { Container, Flex, Grid, Heading, Text, jsx } from 'theme-ui'
import { useTranslate } from '@chordcommerce/gatsby-theme-autonomy'
import ReturnButton from '../Return'
import SubscriptionInfoNote from '../Subscription/SubscriptionInfo'
import { toURL } from '~/utils/intl/localePrefix'

const ProfileLayout = ({
  children,
  title,
  link,
  isSubscriptionPage,
  isOrderHistoryPage,
}) => {
  const translate = useTranslate()
  return (
    <Container m="auto" sx={{ minHeight: '60.6vh' }}>
      <Flex
        sx={{
          flexDirection: 'column',
          marginBottom: ['1rem', '2rem'],
          marginTop: ['0', null, '42px'],
          textAlign: ['center', null, 'left'],
          padding: ['24px 0px 0px', '42px 36px 20px'],
        }}
      >
        <Flex
          sx={{
            flexDirection: ['column-reverse', 'row'],
            lineHeight: '60px',
            gap: ['20px', 0],
            mb: ['20px', 0],
          }}
        >
          <ReturnButton to={toURL(location && location.pathname, link)} />

          <Heading
            as="h1"
            variant="text.h4"
            sx={{
              position: ['relative', 'absolute'],
              left: '50%',
              transform: 'translateX(-50%)',
              display: 'inline',
              verticalAlign: 'middle',
              textAlign: 'center',

              color: 'secondary',
            }}
          >
            {title}
          </Heading>
        </Flex>
      </Flex>
      {children}
      {isSubscriptionPage && (
        <SubscriptionInfoNote
          forwardSx={{
            textAlign: 'center',
            color: 'secondary',
            border: 0,
            fontSize: '18px',
            a: {
              display: 'block',
              textAlign: 'center',
              family: 'sofia',
              fontWeight: 'bold',
            },
          }}
        />
      )}
      {isOrderHistoryPage && (
        <Grid
          sx={{
            placeItems: 'center',
            textAlign: 'center',
            fontSize: '1.125rem',
            my: '5rem',
            color: 'japaneseLaurel',
          }}
        >
          <Text as="p">{translate('account.orders.help')}</Text>
          <a href="mailto:help@goodles.com" sx={{ fontWeight: 'bold' }}>
            help@goodles.com
          </a>
        </Grid>
      )}
    </Container>
  )
}

export default ProfileLayout
