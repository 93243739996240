/** @jsx jsx */
import { useTranslate } from '@chordcommerce/gatsby-theme-autonomy'
import React from 'react'
import { Box, Flex, Grid, Heading, jsx } from 'theme-ui'
import MobileTableElement from './MobileTableElement'
import ShipmentState from './ShipmentState'
import SeeDetailsButton from './SeeDetailsButton'
import Spinner from '~/components/Generic/Spinner'
import useOrders from '~/hooks/utils/use-orders'
import { formatDate, formatMoney } from '~/utils/format'
import { localeFromPathname } from '~/utils/intl/localePrefix'

const OrderHistory = () => {
  const { data, isLoading, error } = useOrders()
  const translate = useTranslate()

  if (error)
    return (
      <Grid sx={{ placeItems: 'center', minHeight: '10rem' }}>
        <Heading>{translate('error.api.default')}</Heading>
      </Grid>
    )

  const { orders } = data ?? {}

  if (isLoading || !orders)
    return (
      <Grid sx={{ placeItems: 'center' }}>
        <Spinner />
      </Grid>
    )
  const { code: locale } = localeFromPathname()

  if (!orders.length) {
    return (
      <Grid sx={{ placeItems: 'center', minHeight: '10rem' }}>
        <Heading>{translate('account.orders.empty')}</Heading>
      </Grid>
    )
  }

  return (
    <>
      {/* Mobile */}
      <Box
        sx={{
          display: ['block', 'none'],
          color: 'japaneseLaurel',
          fontSize: '1rem',
          px: '1rem',
        }}
      >
        {orders.map(order => (
          <Grid
            key={order.id}
            sx={{
              gridTemplateColumns: '1fr 2fr',
              py: '2.5rem',
              borderBottom: '1px solid',
              borderBottomColor: 'schoolBusYellow',
              '&:last-of-type': {
                borderBottom: 'none',
              },
            }}
          >
            <MobileTableElement title={translate('account.orders.date')}>
              {formatDate(order.completedAt, { locale: locale ?? 'en-US' })}
            </MobileTableElement>
            <MobileTableElement title={translate('account.orders.tracking')}>
              <ShipmentState shipments={order.shipments} />
            </MobileTableElement>
            <MobileTableElement title={translate('account.orders.total')}>
              {formatMoney(order.total, { locale, currency: order.currency })}
            </MobileTableElement>
            <MobileTableElement
              title={translate('account.orders.order_number')}
            >
              {order.number}
            </MobileTableElement>
            <Flex sx={{ gridColumn: 'span 2', mt: '2rem' }}>
              <SeeDetailsButton orderNumber={order.number} />
            </Flex>
          </Grid>
        ))}
      </Box>
      {/* Desktop */}
      <table
        sx={t => ({
          display: ['none', 'table'],
          color: 'japaneseLaurel',
          borderCollapse: 'collapse',
          maxWidth: '60rem',
          mx: 'auto',
          width: '100%',
          fontSize: '1.25rem',
          th: {
            textAlign: 'left',
            fontWeight: 400,
          },
          tr: {
            borderBottom: `1px solid ${t.colors.schoolBusYellow}`,
            '&:last-of-type': {
              borderBottom: 'none',
            },
          },
          td: {
            py: '1.75rem',
            fontWeight: 'bold',
          },
        })}
      >
        <thead>
          <tr>
            <th>{translate('account.orders.date')}</th>
            <th>{translate('account.orders.tracking')}</th>
            <th>{translate('account.orders.order_number')}</th>
            <th>{translate('account.orders.total')}</th>
          </tr>
        </thead>
        <tbody>
          {orders.map(order => (
            <tr key={order.id}>
              <td>{formatDate(order.completedAt, { locale })}</td>
              <td>
                <ShipmentState shipments={order.shipments} />
              </td>
              <td>{order.number}</td>
              <td>
                {formatMoney(order.total, { locale, currency: order.currency })}
              </td>
              <td>
                <SeeDetailsButton orderNumber={order.number} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  )
}

export default OrderHistory
