/** @jsx jsx */
import { Flex, Text, jsx } from 'theme-ui'

const TableElement = ({
  children,
  title,
  separator,
  topPadding,
  forwardSx,
}) => {
  return (
    <Flex
      sx={t => ({
        justifyContent: 'space-between',
        pb: '1.5rem',
        pt: topPadding ? '1.5rem' : '0',
        fontSize: '1.25rem',
        borderBottom: separator
          ? `1px solid ${t.colors.schoolBusYellow}`
          : 'none',
        flexWrap: 'wrap',
        ...forwardSx,
      })}
    >
      <Text>{title}</Text>

      <Text sx={{ fontWeight: 'bold' }}>{children}</Text>
    </Flex>
  )
}

export default TableElement
