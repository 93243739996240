/** @jsx jsx */
import { Link } from 'gatsby'
import { jsx, useColorMode } from 'theme-ui'
import Text from '~/components/IntlComponents/Text'
import Arrow from '~/assets/images/icons/long-arrow.svg'
import { useTranslate } from '@chordcommerce/gatsby-theme-autonomy'

const ReturnButton = ({ to }) => {
  const translate = useTranslate()
  return (
    <Text
      as={Link}
      variant="text.subscription"
      sx={{ verticalAlign: 'middle', fontSize: '18px' }}
      to={to}
    >
      <Arrow sx={{ mr: '14px', width: '33px' }} /> {translate('account.back')}
    </Text>
  )
}

export default ReturnButton
