import React, { useState } from 'react'
import { useAuth } from '@chordcommerce/react-autonomy'
import { useForm } from 'react-hook-form'
import { Box, Button, Flex, Heading, Input, Label, Text } from 'theme-ui'
import { useTranslate } from '@chordcommerce/gatsby-theme-autonomy'
import GoogleIcon from '~/assets/images/logos/google-logo.svg'
import AppleIcon from '~/assets/images/logos/apple-logo.svg'

const AuthLoginForm = () => {
  let baseUrl
  if (typeof window !== 'undefined') baseUrl = window.location.origin

  const translate = useTranslate()
  const [email, setEmail] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [alreadySubmited, setAlreadySubmited] = useState(false)
  const [error, setError] = useState('')
  const { serviceClient } = useAuth()
  const { register, handleSubmit } = useForm()

  const onSubmit = handleSubmit(async fields => {
    setIsLoading(true)
    setEmail(fields.email)
    const response = await serviceClient.magicLinks.email
      .loginOrCreate(fields.email, {
        login_magic_link_url: `${window.location.origin}/authenticate`,
        signup_magic_link_url: `${window.location.origin}/authenticate`,
      })
      .catch(e => {
        if (e.message.includes('invalid_email')) {
          setError('Please enter a valid Email Address')
          setIsLoading(false)
          return
        }
      })

    if (!response.status_code || response.status_code !== 200) {
      setError('Something went wrong. Please try again later')
      setIsLoading(false)
      return
    }

    setIsLoading(false)
    setAlreadySubmited(true)
  })

  const signInWithApple = async () => {
    const response = await serviceClient.oauth.apple.start({
      login_redirect_url: `${window.location.origin}/authenticate`,
      signup_redirect_url: `${window.location.origin}/authenticate`,
    })
  }

  const signInWithGoogle = async () => {
    const response = await serviceClient.oauth.google.start({
      login_redirect_url: `${window.location.origin}/authenticate`,
      signup_redirect_url: `${window.location.origin}/authenticate`,
    })
  }

  if (alreadySubmited) {
    return (
      <Flex
        sx={{
          flexDirection: 'column',
          alignItems: 'center',
          my: '5rem',
          px: '2rem',
        }}
      >
        <Heading
          as="h3"
          variant="text.h5"
          sx={{
            textAlign: 'center',
            color: 'tertiary',
            fontSize: ['28px', '40px'],
            marginBottom: ['3rem', '2rem'],
          }}
        >
          CHECK YOUR EMAIL
        </Heading>

        <Text
          variant="text.body"
          sx={{
            textAlign: 'center',
            color: 'tertiary',
            textTransform: 'none',
            mb: '1rem',
            fontSize: '1.25rem',
            fontWeight: '700',
          }}
        >
          We sent an email to {email}. Please click the link to log in or sign
          up.{' '}
        </Text>
        <Text
          variant="text.body"
          sx={{
            textAlign: 'center',
            color: 'tertiary',
            textTransform: 'none',
            mt: '2rem',
            mb: '1rem',
            fontSize: '1rem',
          }}
        >
          Didn&apos;t get an email?
        </Text>
        <Button
          type="submit"
          sx={{
            color: 'primary',
            ':hover': { color: '#FFDD00' },
            width: 'auto',
          }}
          onClick={() => setAlreadySubmited(false)}
        >
          <Text variant="link" sx={{ fontSize: '1rem' }}>
            {translate('login.try_again')}
          </Text>
        </Button>
      </Flex>
    )
  }
  return (
    <Box sx={{ my: '5rem' }}>
      <Heading
        as="h1"
        variant="text.h3"
        sx={{
          textAlign: 'center',
          color: 'tertiary',
          fontSize: ['28px', '40px'],
          marginTop: ['25px', null, '36px'],
          marginBottom: ['40px', '40px'],
        }}
      >
        {translate('login.page_title')}
      </Heading>
      <Flex
        as="form"
        onSubmit={onSubmit}
        sx={{
          flexDirection: 'column',
          mx: '2rem',
          color: 'tertiary',
        }}
      >
        <Flex mb="2" sx={{ flexDirection: 'column' }}>
          <Label variant="text.formLabel" mb="1" htmlFor="email">
            {translate('profile.email_input_label')}
          </Label>
          <Input
            variant="forms.input"
            {...register('email')}
            type="text"
            sx={{ mb: '30px', borderWidth: '2px', background: 'white' }}
          />

          <Button
            type="submit"
            sx={{
              color: 'primary',
              ':hover': { color: '#FFDD00' },
              width: '100%',
            }}
          >
            <Text variant="link">{translate('login.sign_in')}</Text>
          </Button>
        </Flex>
        {error && (
          <Text
            variant="text.formError"
            className="error"
            sx={{
              color: '#FF2815',
              py: '0.5rem',
              textAlign: 'center',
              textTransform: 'capitalize',
            }}
          >
            {error.message ?? error}
          </Text>
        )}
      </Flex>
      <Flex
        sx={{
          width: '100%',
          alignItems: 'center',
          mt: '1rem',
          px: ['1rem', 0, 0],
          mb: error ? '2.5rem' : '1rem',
        }}
      >
        <Box
          sx={{
            width: 'max(5rem, 50%)',
            backgroundColor: 'tertiary',
            height: '2px',
          }}
        />
        <Text
          sx={{
            mx: '1rem',
            mb: '0.25rem',
            fontSize: '1.25rem',
            color: 'tertiary',
          }}
        >
          or
        </Text>
        <Box
          sx={{
            width: 'max(5rem, 50%)',
            backgroundColor: 'tertiary',
            height: '2px',
          }}
        />
      </Flex>
      <Flex>
        <Button
          onClick={signInWithGoogle}
          sx={{
            display: 'flex',
            width: '100%',
            fontSize: ['min(1rem, 5vw)', '1rem', '1.5rem'],
            fontWeight: '400',
            fontFamily: 'heading',
            justifyContent: 'center',
            alignItems: 'center',
            mx: '2rem',
            mb: ['1.4rem', '2.4rem'],
            py: ['1rem !important', '1.2rem !important'],
            color: 'tertiary',
            borderColor: 'currentColor',
            borderWidth: '2px',
            borderStyle: 'solid',
            backgroundColor: 'transparent',
            svg: {
              width: ['1rem', '1.5rem', '2rem'],
              mr: '1rem',
              fill: 'tertiary',
            },
            '&:hover': {
              svg: {
                fill: 'primary',
              },
              backgroundColor: 'tertiary',
              color: 'primary',
            },
          }}
        >
          <GoogleIcon />
          Continue with Google
        </Button>
      </Flex>
      <Flex>
        <Button
          onClick={signInWithApple}
          sx={{
            display: 'flex',
            width: '100%',
            fontSize: ['min(1rem, 5vw)', '1rem', '1.5rem'],
            fontWeight: '400',
            fontFamily: 'heading',
            justifyContent: 'center',
            alignItems: 'center',
            mx: '2rem',
            mb: ['1.4rem', '2.4rem'],
            py: ['1rem !important', '1.2rem !important'],
            color: 'tertiary',
            borderColor: 'currentColor',
            borderWidth: '2px',
            borderStyle: 'solid',
            backgroundColor: 'transparent',
            svg: {
              width: ['1rem', '1.5rem', '2rem'],
              mr: '1rem',
              mb: '0.2rem',
              fill: 'tertiary',
            },
            '&:hover': {
              svg: {
                fill: 'primary',
              },
              backgroundColor: 'tertiary',
              color: 'primary',
            },
          }}
        >
          <AppleIcon />
          Continue with Apple
        </Button>
      </Flex>
      <Flex
        mb="2"
        sx={{ flexDirection: 'column', mx: '2rem', color: 'tertiary' }}
      >
        <Text sx={{ fontSize: '1.1rem', textAlign: 'center', mt: '1rem' }}>
          If you purchased with us before, please use the same email address to
          see past orders and/or subscriptions. If a different email is used, a
          new account will be created.
        </Text>
      </Flex>
    </Box>
  )
}

export default AuthLoginForm
