/** @jsx jsx */
import { useEffect } from 'react'
import { navigate } from 'gatsby'
import { useAuth, useUser } from '@chordcommerce/gatsby-theme-autonomy'
import { jsx } from 'theme-ui'
import { useLocation } from '@reach/router'
import { LoadingSpinner } from '../LoadingSpinner'
import { toURL } from '~/utils/intl/localePrefix'

const PrivateRoute = ({ component: Component, ...props }) => {
  const { getToken, isLoggedIn, isFetching } = useAuth()
  const { user, loadUser } = useUser()
  const location = useLocation()
  useEffect(() => {
    const checkStatus = async () => {
      try {
        await getToken()
        await loadUser()
      } catch (error) {
        navigate(toURL(location && location.pathname, '/account/login'))
      }
    }

    if (!isLoggedIn) checkStatus()
  }, [isLoggedIn, getToken, loadUser, user])

  if (!isLoggedIn && isFetching) {
    return <LoadingSpinner />
  }

  if (!isLoggedIn && !isFetching) {
    return null
  }

  return <Component {...props} />
}

export default PrivateRoute
