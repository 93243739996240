/** @jsx jsx */
import { jsx, useColorMode } from 'theme-ui'
import { useEffect } from 'react'
import { useTranslate } from '@chordcommerce/gatsby-theme-autonomy'
import ProfileLayout from '../../Layout'
import OrderHistory from '../Orders'

const OrderHistoryPage = () => {
  const [, setColorMode] = useColorMode('cheddy-mac')
  const translate = useTranslate()
  useEffect(() => {
    setColorMode('cheddy-mac')
  }, [setColorMode])

  return (
    <ProfileLayout
      title={translate('account.orders.page_title')}
      link="/account"
      isOrderHistoryPage={true}
    >
      <OrderHistory />
    </ProfileLayout>
  )
}

export default OrderHistoryPage
