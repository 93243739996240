import { useAuth } from '@chordcommerce/react-autonomy'
import useSWR from 'swr'
import humps from 'humps'

export default function useOrder(orderNumber) {
  const { token } = useAuth()

  const baseUrl = process.env.GATSBY_ACS_API_URL
  const fetcher = (url, token) =>
    fetch(url, {
      headers: {
        Authorization: token,
      },
    }).then(res => res.json())

  const { data, error, isLoading } = useSWR(
    [`${baseUrl}/api/orders/${orderNumber}`, token],
    fetcher,
  )

  const order = humps.camelizeKeys(data)

  return {
    order,
    isLoading,
    error,
  }
}
