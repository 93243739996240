/** @jsx jsx */
import { useEffect, useState } from 'react'
import { Box, Flex, Button, jsx, useColorMode } from 'theme-ui'

import copy from 'copy-to-clipboard'
import { useTranslate, useUser } from '@chordcommerce/gatsby-theme-autonomy'
import { useAuth } from '@chordcommerce/react-autonomy'
import { useLocation } from '@reach/router'
import StarButton from './StarButton'
import Heading from '~/components/IntlComponents/Heading'
import Text from '~/components/IntlComponents/Text'

import { toURL } from '~/utils/intl/localePrefix'

const AccountPage = () => {
  const translate = useTranslate()
  const { user, loadUser, loadUserReferralIdentifier } = useUser()
  const { logout } = useAuth()
  const [_, setColorMode] = useColorMode()
  const [copied, setCopied] = useState(false)
  const location = useLocation()

  //run on mount
  useEffect(() => {
    loadUser()
    if (user.data.email) {
      loadUserReferralIdentifier({ email: user.data.email })
    }
    setColorMode('cheddy-mac')
  }, [user?.data?.storeCredit])

  const userStoreCredits =
    user.data.storeCredit && user.data.storeCredit !== '0.0'
      ? parseInt(user.data.storeCredit).toFixed(2)
      : null

  const referralPurl = user.referralIdentifier && user.referralIdentifier.purl

  return (
    <Box
      sx={{
        margin: ['0 auto 1.5rem auto', null, '42px auto 4.5rem auto'],
        maxWidth: '800px',
      }}
    >
      <Flex
        sx={{
          display: 'grid',
          gridGap: 4,
          marginTop: ['0', null, '42px'],
          textAlign: ['center', null, 'left'],
          justifyItems: 'center',
          padding: ['24px 16px', null, '42px 36px'],
        }}
      >
        <Heading as="h1" variant="text.h3" color="secondary">
          {user.data.name ? user.data.name + '’s' : ''}{' '}
          {translate('account.page_title')}
        </Heading>
        <Flex
          sx={{
            flexDirection: 'column',
            alignItems: 'center',
            gap: '20px',
            mb: '20px',
          }}
        >
          {userStoreCredits && (
            <Text
              color="secondary"
              sx={{ fontFamily: 'body', fontWeight: 400 }}
            >
              {/* TODO locale */}
              Available in-store credits:{' '}
              <span sx={{ fontFamily: 'heading' }}>${userStoreCredits}</span>
            </Text>
          )}
          {referralPurl && (
            <Button
              onClick={() => {
                copy(referralPurl)
                setCopied(true)
                setTimeout(() => {
                  setCopied(false)
                }, 2000)
              }}
              disabled={copied}
              sx={{
                width: '100%',
                minWidth: '180px',
                maxWidth: '237px',
                margin: '0 auto',
              }}
            >
              {copied
                ? translate('referral.copy_link_button_clicked')
                : translate('referral.copy_link_button')}
            </Button>
          )}
        </Flex>
        <Flex
          sx={{
            gap: ['20px', '54px'],
            mb: '40px',
            flexDirection: ['column', 'row'],
          }}
        >
          <StarButton
            forwardSx={{ img: { height: ['50%', '70px'] } }}
            slug={toURL(location && location.pathname, '/account/profile')}
            icon="/images/logo.png"
            background="#FF5A47"
            title={translate('profile.page_title')}
          ></StarButton>
          <StarButton
            forwardSx={{ img: { height: ['75%', '130px'] } }}
            slug={toURL(
              location && location.pathname,
              '/account/subscriptions',
            )}
            icon="/images/variety.png"
            background="#007300"
            title={translate('subscriptions.page_title')}
          ></StarButton>
          <StarButton
            forwardSx={{ img: { height: ['75%', '130px'] } }}
            slug="/account/orders"
            icon="/buggy.png"
            background="#7833DE"
            title={translate('account.orders.page_title')}
          ></StarButton>
        </Flex>
        <Button
          onClick={logout}
          variant="buttons.primary"
          sx={{ maxWidth: '200px', width: '100%' }}
        >
          {translate('account.logout')}
        </Button>
      </Flex>
    </Box>
  )
}

export default AccountPage
