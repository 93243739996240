export const formatMoney = (amount, { locale, currency }) => {
  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency,
  })

  return formatter.format(amount)
}

export const formatDate = (date, { locale }) => {
  const formatter = new Intl.DateTimeFormat(locale, {
    year: '2-digit',
    month: 'numeric',
    day: 'numeric',
  })

  return formatter.format(new Date(date))
}
