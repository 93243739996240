/** @jsx jsx */
import { useTranslate } from '@chordcommerce/gatsby-theme-autonomy'
import React from 'react'
import { Text, jsx } from 'theme-ui'

const ShipmentState = ({ shipments }) => {
  const translate = useTranslate()
  if (!shipments?.length) return null
  return (
    <>
      {shipments.map(shipment => {
        const isShipped =
          shipment.trackingUrl !== null || shipment.externalTrackingUrl !== null
        const iscanceled = shipment.state === 'canceled'
        return (
          <Text
            key={shipment.id}
            sx={{
              textTransform: 'uppercase',
              backgroundColor: iscanceled
                ? '#FF5A47'
                : isShipped
                ? 'blue'
                : 'grey',
              color: 'white',
              px: '1rem',
              py: '0.25rem',
              borderRadius: '5rem',
              fontSize: '0.875rem',
            }}
          >
            {iscanceled ? (
              'Canceled'
            ) : isShipped ? (
              <a
                href={shipment.trackingUrl || shipment.externalTrackingUrl}
                target="_blank"
                rel="noreferrer"
              >
                {shipment.tracking}
              </a>
            ) : (
              translate('account.orders.tracking_number_unavailable')
            )}
          </Text>
        )
      })}
    </>
  )
}

export default ShipmentState
