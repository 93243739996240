/** @jsx jsx */
import { Box, jsx } from 'theme-ui'
import useSubscriptionInfo from '~/hooks/graphql/queries/intlQueries/use-subscription-info'

const SubscriptionInfoNote = ({ forwardSx }) => {
  const subscriptionInfo = useSubscriptionInfo()

  return subscriptionInfo?.notification?.description ? (
    <Box
      variant="text.body"
      sx={{
        borderRadius: '8px',
        border: '2px #FFDD00 solid',
        marginBottom: '20px',
        color: 'inherit',
        padding: '10px',
        a: {
          textDecoration: 'underline',
          ':hover': {
            color: '#007300',
          },
        },
        ...forwardSx,
      }}
      dangerouslySetInnerHTML={{
        __html:
          subscriptionInfo.notification.description.childMarkdownRemark.html,
      }}
    />
  ) : null
}

export default SubscriptionInfoNote
