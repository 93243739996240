/** @jsx jsx */
import { Box, jsx } from 'theme-ui'
import { useTranslate } from '@chordcommerce/gatsby-theme-autonomy'
const State = ({ state, forwardSx }) => {
  const translate = useTranslate()
  return (
    <Box
      sx={{
        my: 'auto',
        width: 'fit-content',
        color: 'white',
        fontFamily: 'sofia',
        fontSize: '14px',
        lineHeight: '24px',
        backgroundColor:
          state === 'active'
            ? '#03A009'
            : state === 'canceled'
            ? '#7B2DFF'
            : state === 'pending_cancellation'
            ? '#FF5A47'
            : '#008BFA',
        textTransform: 'uppercase',
        px: '17px',
        py: '4px',
        borderRadius: '70px',
        ...forwardSx
      }}
    >
      {translate(`subscriptions.${state}`)}
    </Box>
  )
}

export default State
