/** @jsx jsx */
import { Grid, jsx } from 'theme-ui'

const RowWrapper = ({ children }) => {
  return (
    <Grid
      sx={{
        gridAutoFlow: [null, 'column'],
        width: '100%',
        gap: 0,
        gridTemplateColumns: ['1fr 138px', '1fr 1fr 138px'],
        alignItems: 'center',
        mb: '40px',
        'span:first-child': {
          gridColumn: ['1 / -1', 'auto']
        }
      }}
    >
      {children}
    </Grid>
  )
}

export default RowWrapper
