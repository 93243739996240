/** @jsx jsx */
import { useEffect } from 'react'
import { jsx, useColorMode } from 'theme-ui'
import {
  useTranslate,
  useSubscriptions,
} from '@chordcommerce/gatsby-theme-autonomy'
import ProfileLayout from '../../Layout'
import SubscriptionList from '~/components/Account/Subscription/List'

const AccountSubscriptionPage = () => {
  const translate = useTranslate()
  const { subscriptions } = useSubscriptions()
  const [_, setColorMode] = useColorMode()

  //run on mount
  useEffect(() => {
    setColorMode('cheddy-mac')
  }, [setColorMode])

  return (
    <ProfileLayout
      title={translate('subscriptions.page_title')}
      link="/account"
      isSubscriptionPage={true}
    >
      <SubscriptionList subscriptions={subscriptions} />
    </ProfileLayout>
  )
}

export default AccountSubscriptionPage
