import { loadStripe } from '@stripe/stripe-js'

// Call this outside of component to avoid rerenders.
let stripePromise
export const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(process.env.GATSBY_STRIPE_PK_KEY, {
      stripeAccount: process.env.GATSBY_STRIPE_CONNECTED_ACCOUNT
    })
  }

  return stripePromise
}
