import { useStaticQuery, graphql } from 'gatsby'

// Luis Fernando
const useProducts = () => {
  const data = useStaticQuery(graphql`
    query AllProductsQuery {
      contentfulCatalog(slug: { eq: "catalog" }) {
        collections {
          products {
            ...ProductCardFragment
            productType {
              slug
            }
          }
        }
      }
    }
  `)

  const products = data.contentfulCatalog.collections.reduce(
    (acc, c) => (!c.products ? acc : [...acc, ...c.products]),
    [],
  )

  return products
}

export default useProducts
