/** @jsx jsx */
import { Flex, Box, Button, jsx } from 'theme-ui'
import { useTranslate } from '@chordcommerce/gatsby-theme-autonomy'
import { useContext } from 'react'
import { Link } from 'gatsby'
import Text from '~/components/IntlComponents/Text'
import SubscriptionCard from '~/components/Account/Subscription/SubscriptionCard'
import { ChordSubscriptionContext } from '~/components/Account/Subscription/Context/SubscriptionContext'

const SubscriptionList = ({}) => {
  const subscriptions = useContext(ChordSubscriptionContext)
  if (!subscriptions || subscriptions.length === 0) return <EmptyList />

  const sortedSubscriptions = subscriptions.sort(
    (subscription1, subscription2) => {
      return (
        new Date(subscription2.createdAt) - new Date(subscription1.createdAt)
      )
    },
  )

  // sort subscriptions by their state, if cancelled, send to bottom of list.
  const sendCancelledBottom = (a, b) => {
    if (a.state === 'canceled' && b.state !== 'canceled') {
      return 1
    }
    if (a.state !== 'canceled' && b.state === 'canceled') {
      return -1
    } else {
      return 0
    }
  }
  sortedSubscriptions.sort(sendCancelledBottom)
  return (
    <Box>
      {sortedSubscriptions.map(subscription => (
        <SubscriptionCard key={subscription.id} subscription={subscription} />
      ))}
    </Box>
  )
}

const EmptyList = () => {
  const translate = useTranslate()

  return (
    <Flex
      sx={{
        mb: ['109px', '180px'],
        flexDirection: 'column',
        alignItems: 'center',
        gap: '35px',
      }}
    >
      <Text
        as="h2"
        sx={{
          textAlign: 'center',
          fontSize: '18px',
          color: 'secondary',
        }}
      >
        {translate('subscriptions.no_subscriptions_yet')}
      </Text>
      <Button as={Link} variant="buttons.subscription" to="/shop/">
        <Text>{translate('collections.product_button')}</Text>
      </Button>
    </Flex>
  )
}

export default SubscriptionList
