/** @jsx jsx */
import { useEffect } from 'react'
import { jsx, useColorMode } from 'theme-ui'
import ProfileLayout from '../../Layout'
import AccountProfileForm from './../Form'

const AccountProfilePage = () => {
  const [_, setColorMode] = useColorMode()

  //run on mount
  useEffect(() => {
    setColorMode('cheddy-mac')
  }, [])

  return (
    <ProfileLayout title="Profile" link="/account/">
      <AccountProfileForm />
    </ProfileLayout>
  )
}

export default AccountProfilePage
