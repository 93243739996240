/** @jsx jsx */
import { useTranslate } from '@chordcommerce/gatsby-theme-autonomy'
import { Link } from 'gatsby'
import { jsx, Text } from 'theme-ui'

const SeeDetailsButton = ({ orderNumber }) => {
  const translate = useTranslate()
  return (
    <Link
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'schoolBusYellow',
        textAlign: 'center',
        px: '1rem',
        py: '0.625rem',
        borderRadius: '0.5rem',
        width: '100%',
        maxWidth: '12.5rem',
        maxHeight: '3rem',
        mx: 'auto',
      }}
      to={`/account/orders/${orderNumber}`}
    >
      <Text>{translate('account.orders.more_details')}</Text>
    </Link>
  )
}

export default SeeDetailsButton
