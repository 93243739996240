/** @jsx jsx */
import { Button, Flex, jsx, Box } from 'theme-ui'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useSubscription } from '@chordcommerce/gatsby-theme-autonomy'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import SubscriptionBillingAddress from './BillingAddress'
import Text from '~/components/IntlComponents/Text'
import Spinner from '~/components/Generic/Spinner'

const inputStyle = {
  gridColumn: '2 / -1',
  border: '1px solid',
  borderColor: 'secondary',
  color: 'secondary',
  borderRadius: '8px',
  background: 'white',
  '&:focus': { color: 'secondary', borderColor: 'secondary' },
}

const flexStyle = {
  display: ['flex', 'grid'],
  gridTemplateColumns: '1fr 1fr 138px',
  flexDirection: ['column', 'row'],
  justifyContent: 'space-between',
  mb: '21px',
  alignItems: ['left', 'center'],
}

const textStyle = {
  textAlign: 'left',
  marginRight: '3rem',
}

// TODO: address validation

const StripeCard = ({ forwardSx, close, subscription, ...props }) => {
  const stripe = useStripe()
  const elements = useElements()
  const [loading, setLoading] = useState(false)

  const { updateSubscription } = useSubscription({
    subscriptionId: subscription.id,
  })
  const [addressEdit, setAddressEdit] = useState(false)

  const [error, setError] = useState(null)

  const [address, setAddress] = useState(subscription.billAddress)

  const handleAddressClose = e => {
    e.preventDefault()
    setAddressEdit(false)
  }

  const handleClose = e => {
    e.preventDefault()
    close()
  }

  // if we enable billing address changes
  const toggleAddress = e => {
    e.preventDefault()
    setAddressEdit(!addressEdit)
  }

  const card = elements.getElement(CardElement)
  useEffect(() => {
    const handleAddress = e => {
      setAddress({
        ...address,
        zipcode: e.value.postalCode,
      })
    }
    if (card) {
      setLoading(false)
      // grab value of zipcode to send to chord OMS
      card.on('change', handleAddress)
    } else {
      setLoading(true)
    }
    return () => {
      if (card) {
        card.removeEventListener('change', handleAddress)
      }
    }
  }, [card])

  const { register, handleSubmit } = useForm()

  const onSubmit = async data => {
    setLoading(true)
    setError(null)
    try {
      if (!stripe || !elements) {
        return
      } else {
        const { error: stripeError, paymentMethod } =
          await stripe.createPaymentMethod({
            type: 'card',
            card,
            billing_details: {
              name: subscription.billAddress.name,
              address: {
                line1: address.address1,
                line2: address.address2,
                city: address.city,
                country: address.countryIso,
                state: address.stateName,
                postal_code: address.zipcode,
              },
            },
          })
        const payload = {
          bill_address_attributes: {
            name: address.name,
            address1: address.address1,
            address2: address.address2,
            city: address.city,
            zipcode: address.zipcode,
            phone: null,
            company: null,
            alternative_phone: null,

            country_iso: address.countryIso,
            state_name: address.stateName || address.state.name,
          },
          payment_source_attributes: {
            month: paymentMethod.card.exp_month,
            year: paymentMethod.card.exp_year,
            cc_type: paymentMethod.card.brand,
            last_digits: paymentMethod.card.last4,
            name: paymentMethod.billing_details.name,
            gateway_payment_profile_id: paymentMethod.id,
          },
        }

        await updateSubscription(payload)
        close()
      }
    } catch (e) {
      setError(e)
    } finally {
      setLoading(false)
    }
  }
  return (
    <Box {...props} as="form" onSubmit={handleSubmit(onSubmit)}>
      <Flex sx={flexStyle}>
        <Text sx={textStyle}>Card:</Text>
        <Box sx={{ gridColumn: '2 / -1' }}>
          <Box
            sx={{
              width: '100%',
              padding: '10px',
              backgroundColor: 'white',
              ...inputStyle,
            }}
          >
            <CardElement
              options={{
                value: { postalCode: subscription.billAddress.zipcode },
                style: {
                  base: {
                    width: '100%',
                    fontSize: '20px',
                    color: '#007300',
                    iconColor: '#007300',
                    backgroundColor: 'transparent',

                    color: '#007300',
                    fontSmoothing: 'antialiased',
                    ':-webkit-autofill': {
                      color: '#007300',
                    },
                    '::placeholder': {
                      color: '#007300',
                    },
                  },
                  invalid: {
                    color: '#FF5A47',
                  },
                },
              }}
            />
          </Box>

          <Flex
            sx={{
              justifyContent: addressEdit ? 'flex-end' : 'space-between',
              gap: '20px',
              mt: '1rem',
            }}
          >
            {!addressEdit && (
              <Flex>
                <Button
                  type="submit"
                  variant="buttons.subscription"
                  disabled={loading}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  {loading && (
                    <Spinner data-testid="spinner" size="15" color="inherit" />
                  )}
                  {!loading && 'Update'}
                </Button>

                <Button variant="buttons.link" onClick={e => handleClose(e)}>
                  Cancel
                </Button>
              </Flex>
            )}
            {/* Disabled address editing for now. */}
            {/* <Button
            variant="buttons.subscription"
              sx={{
                fontSize: '15px',
                border: '2px solid',
                borderColor: 'secondary',
                background: 'transparent',
                
              }}
              onClick={toggleAddress}
            >
              {addressEdit ? 'Hide Address' : 'Update Address'}
            </Button> */}
          </Flex>
        </Box>
      </Flex>
      {addressEdit && (
        <SubscriptionBillingAddress
          subscription={subscription}
          register={register}
          close={e => handleAddressClose(e)}
          address={address}
          setAddress={setAddress}
          error={error}
          loading={loading}
        />
      )}
    </Box>
  )
}

export default StripeCard
