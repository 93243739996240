import { graphql, useStaticQuery } from 'gatsby'
import { getDataByLocale } from '~/hooks/utils/get-data-by-locale'

const useSubscriptionInfo = locale => {
  const data = useStaticQuery(graphql`
    {
      # English US
      notificationBar_enUS: contentfulNotificationBar(
        slug: { eq: "subscription-help-info" }
        node_locale: { eq: "en-US" }
      ) {
        notification {
          slug
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }

      # English CA
      notificationBar_enCA: contentfulNotificationBar(
        slug: { eq: "subscription-help-info" }
        node_locale: { eq: "en-CA" }
      ) {
        notification {
          slug
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }

      # French CA
      notificationBar_frCA: contentfulNotificationBar(
        slug: { eq: "subscription-help-info" }
        node_locale: { eq: "fr-CA" }
      ) {
        notification {
          slug
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }

      # Hebrew IL
      notificationBar_heIL: contentfulNotificationBar(
        slug: { eq: "subscription-help-info" }
        node_locale: { eq: "he-IL" }
      ) {
        notification {
          slug
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }

      # Spanish MX
      notificationBar_esMX: contentfulNotificationBar(
        slug: { eq: "subscription-help-info" }
        node_locale: { eq: "es-MX" }
      ) {
        notification {
          slug
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  `)

  return getDataByLocale(locale, data, 'notificationBar_') ?? {}
}
export default useSubscriptionInfo
