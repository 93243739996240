import useProducts from '../graphql/queries/use-products'
import { useEffect, useState } from 'react'

// Luis Fernando
const useProductBySku = sku => {
  const products = useProducts()
  const [match, setMatch] = useState()

  useEffect(() => {
    const match = products.find(product =>
      product.variants.find(variant => variant.sku === sku)
    )

    setMatch(match)
  }, [sku])

  return match
}

export default useProductBySku
